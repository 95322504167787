import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/alanmortoni/Code/Personal/qvsr/node_modules/gatsby-theme-docz/src/base/Layout.js";
import technologiesURL from '../assets/technologies.jpg';
import APIHandlerURL from '../assets/api-handler.jpg';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "architecture"
    }}>{`Architecture`}</h1>
    <img src={technologiesURL} style={{
      maxWidth: '550px',
      borderRadius: '8px'
    }} alt="Technologies flow" />
    <h2 {...{
      "id": "api-handler"
    }}>{`API Handler`}</h2>
    <div style={{
      textAlign: 'justify',
      marginBottom: '30px'
    }}>
  QVSR uses{' '}
  <a href="https://firebase.google.com/docs/functions" target="_blank">
    Firebase Functions
  </a>{' '}
  to handle the API, it is a NodeJS + Express serverless application. The orange endpoints require the user to be
  authenticated and to have a valid, non-expired token. While the blue boxes do not require the user to be
  authenticated.
    </div>
    <p>{`Diagram:`}</p>
    <img src={APIHandlerURL} style={{
      maxWidth: '750px',
      borderRadius: '8px'
    }} alt="API handler flow" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      